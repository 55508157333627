/* src/styles/NavbarStyles.css */

.navbar {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 80px; /* Set a fixed height */
  padding: 0 px; /* Optional: Adds padding to the sides */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px); /* Applies the blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support for the blur effect */
}

.logo img {
  height: 40px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
    height: 80px;
    padding-top: 10px;
  }

  .logo {
    position: absolute;
    left: 50%;
    top: 15px;
    transform: translate(-50%);
  }

  .contact-btn {
    display: none; /* Hide larger button on mobile */
  }

  .contact-btn-small {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: block; /* Show smaller button on mobile */
    font-size: 12px;
    padding: 6px 12px;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    cursor: pointer;
  }

  .hamburger-line {
    width: 100%;
    height: 3px;
    background-color: white;
  }

  /* Mobile menu styles */
  .mobile-menu {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    text-align: center;
    padding: 20px 0;
    z-index: 999;
  }

  .mobile-menu li {
    list-style: none;
    margin: 10px 0;
  }

  .mobile-menu li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    display: block;
    padding: 10px;
    transition: color 0.3s ease;
  }

  .mobile-menu li a:hover {
    color: teal;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .contact-btn-small {
    display: none; /* Hide smaller button on desktop */
  }

  .contact-btn {
    display: block; /* Show larger button on desktop */
  }
}

/* Styles for medium screens between 769px and 1023px */
@media (min-width: 769px) and (max-width: 1023px) {
  .navbar {
    justify-content: space-between;
    height: 80px;
    padding-top: 10px;
  }

  .logo {
    position: static; /* Reset positioning to keep it on the left */
    transform: none; /* Remove centering transform */
  }

  .contact-btn {
    display: none; /* Hide larger button on mobile */
  }

  .contact-btn-small {
    display: none;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    cursor: pointer;
  }

  .hamburger-line {
    width: 100%;
    height: 3px;
    background-color: white;
  }

  /* Mobile menu styles */
  .mobile-menu {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    text-align: center;
    padding: 20px 0;
    z-index: 999;
  }

  .mobile-menu li {
    list-style: none;
    margin: 10px 0;
  }

  .mobile-menu li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    display: block;
    padding: 10px;
    transition: color 0.3s ease;
  }

  .mobile-menu li a:hover {
    color: teal;
  }
}



