.div-bgport {
    background-image: url('../images/portfolio.png');
    background-size: cover;
    background-position: center;
    height: 100%; /* Full viewport height */
    position: relative;
  }




