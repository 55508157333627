.media-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Space between items */
  padding: 20px;
  justify-content: center; /* Adjust to make grid items more centered and even */
}

/* Each media item */
.media-item {
  flex: 1 1 auto;
  margin-bottom: 15px;
  width: calc(33.333% - 10px); /* Adjust width to fit items without gaps */
  height: auto;
  position: relative;
  border-radius: 8px; /* Optional rounded corners */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Style for images and iframes to fit properly */
.media-item img,
.media-item iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Lightbox styles for mobile */
/* Lightbox styles for mobile */
@media (max-width: 768px) {
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9); /* Dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .lightbox-content {
    width: 100%; /* Fill entire width */
    height: 100%; /* Fill entire height */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .lightbox-content iframe {
    width: 90%; /* Slight padding */
    height: 90%; /* Slight padding */
    border-radius: 8px;
  }

  .close-lightbox {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    z-index: 1001;
  }

  /* Ensure the media-grid is not affected by the lightbox */
  .media-grid {
    position: relative;
    z-index: 1;
  }
}
