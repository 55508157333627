.header-bg {
  background-image: url('../images/selfie1.jpg');
  background-size: cover;
  background-position: center;
  height: 47.9375rem; /* Converted from 767px (767 / 16 = 47.9375rem) */
  position: relative;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent overlay */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .header-bg {
    background-image: url('../images/selfie1.jpg');
    background-size: cover;
    background-position: center;
    height: 20rem; /* Converted from 767px (767 / 16 = 47.9375rem) */
    position: relative;
  }
}

  
