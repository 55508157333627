.footer-container {
    background-color: #1c1c1c;
    color: #fff;
    padding: 40px 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: center; /* Centers all the content horizontally */
    gap: 800px; /* Space between the sections */
  }
  
  .footer-section {
    text-align: left; /* Keeps the text alignment consistent inside the section */
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .footer-section p {
    margin: 5px 0;
  }
  
  .social-links {
    display: flex;
    gap: 20px;
  }
  
  .social-links a {
    color: #fff;
    font-size: 24px;
    transition: color 0.3s;
  }
  
  .social-links a:hover {
    color: #f5d707; /* Highlight color for social media icons when hovered */
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #ccc;
  }

  /* Responsive adjustments */
@media screen and (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack the content vertically */
    align-items: center;
    gap: 30px; /* Reduce the gap between sections */
  }

  .footer-section {
    text-align: center; /* Center the text on smaller screens */
  }

  .social-links {
    justify-content: center; /* Center the social icons */
    gap: 10px; /* Reduce the space between social icons */
  }

  .footer-bottom {
    font-size: 12px; /* Slightly smaller text for smaller devices */
  }
}
  
  