@tailwind base;
@tailwind components;
@tailwind utilities;

/* Set base font size for responsive design */
html {
  font-size: 100%; /* This makes 1rem = 16px by default */
  scroll-behavior: smooth; /* Enables smooth scrolling for the entire page */
}

/* Apply Poppins font globally */
body {
  font-family: 'Poppins', sans-serif;
}

/* Different font weights */
h1 {
  font-weight: 700; /* Bold */
  font-size: 2.25rem; /* Equivalent to 36px (36px / 16px = 2.25rem) */
}

h2 {
  font-weight: 600; /* SemiBold */
  font-size: 1.875rem; /* Equivalent to 30px (30px / 16px = 1.875rem) */
}

p {
  font-weight: 400; /* Regular */
  font-size: 1rem; /* Equivalent to 16px */
}

/* Applying Italic Style */
.italic-text {
  font-style: italic;
  font-size: 1rem; /* Equivalent to 16px */
}

/* Custom styling */
.custom-heading {
  font-weight: 600; /* SemiBold */
  text-transform: uppercase; /* All uppercase */
  letter-spacing: 0.125rem; /* Equivalent to 2px (2px / 16px = 0.125rem) */
  font-size: 1.5rem; /* Equivalent to 24px (24px / 16px = 1.5rem) */
}

a {
  font-weight: 700; /* Bold */
  font-size: 1rem; /* Equivalent to 16px */
}

