.div-bg {
  background-image: url('../images/servicos.png');
  background-size: cover;
  background-position: center;
  height: 100%; /* Full viewport height */
  position: relative;
  padding-bottom: 5%;
}

.list {
  font-size: 1.5625rem; /* Adjusted for readability */
  font-weight: 400; /* Regular weight */
  color: #ffffff; /* White text color */
  font-style: normal; /* No italic for list items */
  padding-left: 2rem; /* Reduce padding from the left */
  list-style-type: disc; /* Ensure it shows a bullet point */
}

li {
  margin-left: 8rem; /* Small margin to push bullets slightly to the right */
  list-style-position: inside; /* Ensures bullets are inside the padding area */
}

@media screen and (max-width: 768px) {
  .list {
      padding-left: 1rem; /* Reduce padding for mobile screens */
  }
  
  li {
      font-size: 1rem; /* Slightly smaller text for mobile screens */
      line-height: 1.25rem; /* Adjust line height accordingly */
      margin-left: 0.5rem; /* Adjust margin for smaller screens */
  }
}
