/* Ensure the image scales properly */
img {
  max-width: 100%;   /* Prevents the image from growing larger than its container */
  height: auto;      /* Maintains the aspect ratio of the image */
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  margin-left: auto; /* Aligns the image horizontally */
  margin-right: auto; /* Aligns the image horizontally */
  object-fit: contain;
}

.hero-section {
  padding-top: 194px;
  text-align: center; /* Ensures the image stays centered */
  padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
.hero-section {
  padding-top: 100px; /* Adjust padding for smaller screens */
}



img {
  object-fit: contain; /* Prevent the image from covering too much on smaller screens */
}
}

/* Specific styles for widescreen displays */
@media (min-width: 1440px) {
  .hero-section {
    padding: 0 5%; /* Add padding on the sides for extra space */
    padding-top: 50px;
  }

  .hero-section img {
    max-width: 80%; /* Limit image width on large screens */
    height: auto; /* Maintain aspect ratio */
  }
}
